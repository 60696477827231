import ticketCode from '../../components/ticketCode.vue'
import lablePop from '../../components/lablePop.vue' //弹窗
export default {
    name: 'adminOrderInfo',
    components: {
        ticketCode,
        lablePop
    },
    data() {
        return {
            orderInfo: null,
            tickeShow: false, //显示二维码
            id: null,
            pop_show: false,
            is_pop: 0,
            price: 0,
            ticket_type: 0,
            numberValue: '',
            order_id: null,
            user_data: {}
        }
    },
    created() {
        document.title = "订单详情";
        this.$UtilsApi.memberInfo({}).then(res => {
            if (res.data.code == 0) {
                this.user_data = res.data.data;
            }
        });
        this.getOrderInfo();
    },
    methods: {
        toRefundInfo(id, price) {
            console.log(id, price);
            this.$router.push({
                name: 'refund_form',
                query: {
                    id: id,
                    price: price
                }
            })
        },
        hidePop(e) {
            this.pop_show = false;
        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        //获取订单详情
        getOrderInfo() {
            this.$UtilsApi.manageInfo(this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    this.orderInfo = res.data.data;
                    console.log(res.data.data);
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        validateNumber(event) {
            let value = event.target.value
                // 使用正则表达式过滤出非金额格式的字符，除了数字、小数点和负号外，其他字符全部替换为空字符串
            value = value.replace(/[^\d.-]/g, '')
                // 只保留第一个负号和第一个小数点，其他负号和小数点删除
            value = value.replace(/^\-/, '$#$').replace(/\-/g, '').replace('$#$', '-')
            value = value.replace(/^\./, '0.').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
                // 对小数点后的字符进行限制，只保留两位小数
            let pos = value.indexOf('.')
            if (pos !== -1) {
                value = value.substr(0, pos + 1) + value.substr(pos + 1, 2).replace(/[^\d]/g, '')
            }
            if (value > this.price) {
                value = this.price
            }
            this.numberValue = value;

        },
        //退款
        tk(is) {
            if (is) {
                if (!this.numberValue) {
                    this.$notify({ type: 'warning', message: '请正确输入退款金额', duration: 2000 });
                    return
                }
                this.$UtilsApi.refund({
                    order_id: this.order_id,
                    refund_amount: this.numberValue
                }).then(res => {
                    if (res.data.code == 0) {
                        this.is_pop = 2;
                        this.getOrderInfo();
                    } else {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                });
                console.log('退款')
            }
            this.numberValue = '';
            this.is_pop = 0;
        },
        pop(id) {
            this.$UtilsApi.manageInfo(id, {}).then(res => {
                if (res.data.code == 0) {
                    // 获取当前日期对象
                    // let today = new Date();

                    // // 获取时间戳
                    // let timestamp = res.data.data.ticket_time;

                    // // 将时间戳转换为日期对象
                    // let date = new Date(timestamp * 1000);

                    // // 计算今天和输入的日期之间相差的毫秒数
                    // let diffInMs = today.getTime() - date.getTime();

                    // // 将毫秒数转换为天数
                    // let diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1;
                    // if (res.data.data.ticket_type == 2) {
                    //     this.price = (res.data.data.price / res.data.data.ticket_num * (res.data.data.ticket_num - diffInDays));
                    // } else {
                    //     this.price = (res.data.data.price / res.data.data.ticket_num * res.data.data.ticket_surplus_num);
                    // }
                    this.price = res.data.data.price;
                    this.ticket_type = res.data.data.ticket_type;
                    this.order_id = id;
                    this.is_pop = 1;
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        // 根据时间戳转换日期
        getDate(time) {
            var timestr = new Date(parseInt(time) * 1000);
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            var hour = timestr.getHours();
            var minute = timestr.getMinutes();
            var second = timestr.getSeconds();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date) + " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
        }
    },
}